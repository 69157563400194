<script>
import { ArrowUpIcon, UserIcon, MailIcon, KeyIcon, PenToolIcon, ApertureIcon, SmartphoneIcon, MessageCircleIcon, ArrowRightIcon} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import countTo from "vue-count-to";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import Features from "@/components/features";
import Pricing from "@/components/pricing";

/**
 * index-enterprise component
 */
export default {
  data() {
    return {
    };
  },
  components: {
    Navbar,
    Footer,
    Pricing,
    ArrowUpIcon,
    UserIcon,
    MailIcon,
    KeyIcon,
    Carousel,
    Slide,
    Features,
    countTo,
    PenToolIcon,ApertureIcon,SmartphoneIcon,MessageCircleIcon,ArrowRightIcon
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <!-- Hero Start -->
    <section
      class="bg-half-260 bg-primary d-table w-100"
      style="background: url('images/template/enterprise.png') center center"
    >
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="title-heading text-center mt-4">
              <h1 class="display-4 title-dark font-weight-bold text-white mb-3">
                Stop wasting time and get real results <br />on social media
              </h1>
              <p class="para-desc mx-auto text-white-50">
                Producing high-quality PGC content that aligns with local culture and captivates the target market, enhancing brand image and credibility . The aim is to reach a broader audience, providing visual and measurable results to boost brand sales conversion rates and direct bookings.
              </p>
              <!-- <div class="mt-4 pt-2">
                <a href="javascript: void(0);" class="btn btn-primary"
                  ><i class="mdi mdi-email"></i> Get Started</a
                >
              </div> -->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Feature Start -->
    <section class="section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="section-title">
              <h4 class="title mb-4">
                About <span class="text-primary">TREEP</span> Marketing Team
              </h4>
              <p class="text-muted para-desc">
                A leading digital marketing solution provider.
              </p>
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Establishing authoritative and professional official image in your target market.
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Reach out to more users, increasing brand awareness
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Integrating brand promotion with effective operations to drive direct bookings.
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="row ml-lg-5" id="counter">
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <div
                      class="card counter-box border-0 bg-light shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="mb-0">
                          <countTo
                            :startVal="3"
                            :endVal="80"
                            :duration="4000"
                          ></countTo
                          >%
                        </h2>
                        <h5 class="counter-head mb-0">Market share in Top 10 Thai clients in various industries</h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-primary shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0">
                          <countTo
                            :startVal="1"
                            :endVal="50000"
                            :duration="4000"
                          ></countTo
                          >K
                        </h2>
                        <h5 class="counter-head mb-0 title-dark text-light">
                          Reached user base
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end Row-->
              </div>
              <!--end col-->

              <div class="col-md-6 col-12">
                <div class="row pt-lg-4 mt-lg-4">
                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-success shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0">
                          <countTo :startVal="0" :endVal="10" :duration="4000">
                          </countTo
                          >K+
                        </h2>
                        <h5 class="counter-head mb-0 title-dark text-light">
                          Influencer resources
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-light shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="mb-0">
                          <countTo
                            :startVal="3"
                            :endVal="8000"
                            :duration="4000"
                          ></countTo
                          >K
                        </h2>
                        <h5 class="counter-head mb-0">ADs cost</h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end Row-->
              </div>
              <!--end col-->
            </div>
            <!--end Row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Social media channels</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Encompassing billions of users on major market channels in the entire Chinese market.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="row justify-content-center">
          <div class=" mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/social/redbook_logo.jpg" height="60">
                  </div>

              </div>
          </div>
          <div class="mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/social/douyin_logo.jpg" height="60">
                  </div>
              </div>
          </div>
          <div class="mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/social/weibo_logo.jpg" height="60">
                  </div>
              </div>
          </div>
          <div class=" mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/social/wechat_logo.jpg" height="60">
                  </div>

              </div>
          </div>
          <div class="mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/social/wechat_logo2.jpg" height="60">
                  </div>
              </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->

    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Official account operation</h4>
              <p class="text-muted para-desc mb-0 mx-auto">We have an in-depth understanding of Chinese user preferences and excel in creating content that combines localized language with platform trends, ensuring a closer alignment with user needs.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div class="card bg-light rounded shadow border-0">
              <div class="card-body py-5">
                <i class="uil uil-rupee-sign h1 text-primary"></i>
                <div class="mt-2">
                  <h5 class="card-title">
                    <a href="javascript:void(0)" class="text-primary">
                      Verified blue V</a
                    >
                  </h5>
                  <p class="text-muted mt-3 mb-0">
                    Offering verification services to enhance the credibility of your official account.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div class="card bg-light rounded shadow border-0">
              <div class="card-body py-5">
                <i class="uil uil-shield h1 text-primary"></i>
                <div class="mt-2">
                  <h5 class="card-title">
                    <a href="javascript:void(0)" class="text-primary">
                      Building followers communities</a
                    >
                  </h5>
                  <p class="text-muted mt-3 mb-0">
                    Expanding followers communities to accumulate brand user assets, fostering loyal users, and strengthening brand influence.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div class="card bg-light rounded shadow border-0">
              <div class="card-body py-5">
                <i class="uil uil-headphones h1 text-primary"></i>
                <div class="mt-2">
                  <h5 class="card-title">
                    <a href="javascript:void(0)" class="text-primary">
                      Interactive Q&A</a
                    >
                  </h5>
                  <p class="text-muted mt-3 mb-0">
                    Public Reputation Management.<br />
                    Information Correction.<br />
                    Conversion Guidance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div class="card bg-light rounded shadow border-0">
              <div class="card-body py-5">
                <i class="uil uil-exchange h1 text-primary"></i>
                <div class="mt-2">
                  <h5 class="card-title">
                    <a href="javascript:void(0)" class="text-primary">
                      Publish official post</a
                    >
                  </h5>
                  <p class="text-muted mt-3 mb-0">
                    Aligning with platform trends, releasing engaging content, and establishing the official account as a brand representative for a deeper user connection and valuable information accumulation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature End -->
    <!-- Faq Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">
                Influencer collaboration
              </h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Combine KOL and KOC marketing. By inviting influencers for inspection, capturing high-quality content to creatively showcase our product strengths. <br />
                This approach enhances brand exposure, encourages user participation, and strengthens overall brand awareness.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row" id="counter">
          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img
                src="images/template/Asset190.svg"
                class="avatar avatar-small"
                alt=""
              />
              <h2 class="mb-0 mt-4">
                <countTo :startVal="3" :endVal="10000" :duration="4000"></countTo>+
              </h2>
              <h6 class="counter-head text-muted">Access to a diverse pool of influencers exceeding 10,000 in various domains, reaching a broad audience.</h6>
            </div>
            <!--end counter box-->
          </div>

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img
                src="images/template/Asset189.svg"
                class="avatar avatar-small"
                alt=""
              />
              <h2 class="mb-0 mt-4">
                <countTo :startVal="1" :endVal="90" :duration="4000"></countTo>%
              </h2>
              <h6 class="counter-head text-muted">In various fields, 90% of top KOLs have collaborated with us.</h6>
            </div>
            <!--end counter box-->
          </div>

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img
                src="images/template/Asset192.svg"
                class="avatar avatar-small"
                alt=""
              />
              <h2 class="mb-0 mt-4">
                <countTo :startVal="0" :endVal="10000000" :duration="4000"></countTo>+
              </h2>
              <h6 class="counter-head text-muted">Millions of exposures</h6>
            </div>
            <!--end counter box-->
          </div>

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img
                src="images/template/Asset187.svg"
                class="avatar avatar-small"
                alt=""
              />
              <h2 class="mb-0 mt-4">
                <countTo :startVal="3" :endVal="98" :duration="4000"></countTo>%
              </h2>
              <h6 class="counter-head text-muted">Historical satisfaction</h6>
            </div>
            <!--end counter box-->
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-6">
            <div class="faq-content mr-lg-5">
              <div class="accordion" id="accordionExampleone">
                <b-card no-body class="mb-2 border-0 rounded">
                  <b-card-header class="border-0 bg-light p-3 pr-5">
                    <a
                      class="faq position-relative text-dark"
                      v-b-toggle.accordion-1
                      href="javascript: void(0);"
                    >
                      <h6 class="title mb-0">
                        Rapidly build trust
                      </h6>
                    </a>
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="px-2 py-4">
                      <p class="text-muted mb-0 faq-ans">
                        Influencers have already established a level of trust on social media, making their recommendations more readily accepted by users, helping the brand gain trust quickly.
                      </p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-2 border-0 rounded">
                  <b-card-header class="border-0 bg-light p-3 pr-5">
                    <a
                      class="faq position-relative text-dark"
                      v-b-toggle.accordion-2
                      href="javascript: void(0);"
                    >
                      <h6 class="title mb-0">
                        Precise audience coverage
                      </h6>
                    </a>
                  </b-card-header>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="px-2 py-4">
                      <p class="text-muted mb-0 faq-ans">
                        Selecting influencers who align with the brand's target audience allows for more accurate coverage of potential customers, increasing brand awareness in the target market.
                      </p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-2 border-0 rounded">
                  <b-card-header class="border-0 bg-light p-3 pr-5">
                    <a
                      class="faq position-relative text-dark"
                      v-b-toggle.accordion-3
                      href="javascript: void(0);"
                    >
                      <h6 class="title mb-0">
                        High exposure capability
                      </h6>
                    </a>
                  </b-card-header>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="px-2 py-4">
                      <p class="text-muted mb-0 faq-ans">
                        Influencers' content has high exposure capabilities, rapidly spreading in a short time, expanding the brand's influence. Through collaboration with influencers, the brand can quickly attract attention on social media, achieving broader brand exposure.
                      </p>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-2 border-0 rounded">
                  <b-card-header class="border-0 bg-light p-3 pr-5">
                    <a
                      class="faq position-relative text-dark"
                      v-b-toggle.accordion-4
                      href="javascript: void(0);"
                    >
                      <h6 class="title mb-0">
                        Generate word of mouth
                      </h6>
                    </a>
                  </b-card-header>
                  <b-collapse
                    id="accordion-4"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="px-2 py-4">
                      <p class="text-muted mb-0 faq-ans">
                        Influencers' shared content often triggers word-of-mouth spread on social media, promoting UGC content, leading to broader brand exposure and discussions.
                      </p>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-2 border-0 rounded">
                  <b-card-header class="border-0 bg-light p-3 pr-5">
                    <a
                      class="faq position-relative text-dark"
                      v-b-toggle.accordion-5
                      href="javascript: void(0);"
                    >
                      <h6 class="title mb-0">
                        Increase authenticity of UGC content
                      </h6>
                    </a>
                  </b-card-header>
                  <b-collapse
                    id="accordion-5"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="px-2 py-4">
                      <p class="text-muted mb-0 faq-ans">
                        Collaborating with influencers allows brands to obtain high-quality UGC content,enhancing the authenticity of third-party content and bringing the brand closer to users' lives.
                      </p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <img src="images/template/social.svg" alt="" />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container  mt-100 mt-60">
        <div class="row mt-lg-4 align-items-center">
          <div class="col-lg-5 col-md-12 text-center text-lg-left">
            <div class="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">
              <h4 class="title mb-4">Advertising placement</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Achieve comprehensive traffic coverage across all scenarios, reaching a broad range of potential consumers. This not only enhances brand awareness but also strengthens user trust in the brand.
              </p>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-12">
            <div class="ml-lg-5">
              <div class="row align-items-center">
                <div class="col-md-6 col-12 mt-4 pt-4 px-md-0">
                  <div
                    class="card pricing-rates starter-plan shadow rounded border-0"
                  >
                    <div class="card-body py-5">
                      <h6
                        class="title font-weight-bold text-uppercase text-primary mb-4"
                      >
                      Feed ads
                      </h6>
                      <div class="d-flex mb-4">
                        <p class="text para-desc mx-auto mb-0">
                        Feed ads help users explore new consumer information and life inspirations on the discovery page.
                        </p>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                        <li class="h6 text-muted mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Cost-effective high exposure strategy to enhance brand awareness.
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Reaching potential consumers through targeted audience segmentation.
                        </li>
                      </ul>
                      <!-- <a href="javascript:void(0)" class="btn btn-primary mt-4"
                        >Get Started</a
                      > -->
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-6 col-12 mt-4 pt-2 pt-md-4 px-md-0">
                  <div
                    class="card pricing-rates bg-light shadow rounded border-0"
                  >
                    <div class="card-body py-5">
                      <h6
                        class="title font-weight-bold text-uppercase text-primary mb-4"
                      >
                      Search ads
                      </h6>
                      <div class="d-flex mb-4">
                        <p class="text para-desc mx-auto mb-0">
                          Search ads aims to align content with the information users are actively seeking. 
                        </p>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                        <li class="h6 text-muted mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Optimizing keyword rankings to position positive content upfront, enhancing user trust.
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Precisely reaching target users to facilitate decision-making.
                        </li>
                      </ul>
                      <!-- <a href="javascript:void(0)" class="btn btn-primary mt-4"
                        >Try it now</a
                      > -->
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container  mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
              <h4 class="title mb-4">Traffic conversion sales</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Establishing a closed-loop e-commerce system from start to finish, focusing on the Treep Booking Engine, significantly boosts brand exposure, user engagement, and conversion rates, driving substantial business growth.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-md-4 mt-4 pt-2">
            <div
              class="card features work-process bg-transparent process-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-presentation-edit"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">Content</h4>
                <p class="text-muted mb-0">
                  Content created by integrating platform trends and product highlights.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
            <div
              class="card features work-process bg-transparent process-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-airplay"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">Advertising</h4>
                <p class="text-muted mb-0">
                  Comprehensive advertising placement to reach a wider audience.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
            <div
              class="card features work-process bg-transparent d-none-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-image-check"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">Booking</h4>
                <p class="text-muted mb-0">
                  Treep booking engine.
                </p>
                <router-link to="/pms" target="_blank" class="text-primary">
                Booking Engine<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
              </router-link>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>

      <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-8 col--8 col-12">
                    <div class="row mr-lg-4" id="counter">
                        <div class="col-md-6 col-12">
                            <div class="row">
                                <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                    <div class="card explore-feature border-0 rounded text-center bg-white shadow">
                                        <div class="card-body">
                                            <div class="icon rounded-circle shadow-lg d-inline-block">
                                                <pen-tool-icon class="fea"></pen-tool-icon>
                                            </div>
                                            <h5 class="mt-3 title">Creative excellence</h5>
                                            <p class="text-muted mb-0">We boast an exceptionally creative team capable of designing captivating advertisements and content, ensuring your brand stands out in the market.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-12 mt-4 pt-2">
                                    <div class="card explore-feature border-0 rounded text-center bg-white shadow">
                                        <div class="card-body">
                                            <div class="icon rounded-circle shadow-lg d-inline-block">
                                                <aperture-icon class="fea"></aperture-icon>
                                            </div>
                                            <h5 class="mt-3 title">Data-driven</h5>
                                            <p class="text-muted mb-0">Through in-depth analysis and real-time optimization, to ensure you achieve the best marketing results.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end Row-->
                        </div>
                        <!--end col-->

                        <div class="col-md-6 col-12">
                            <div class="row pt-lg-4 mt-lg-4">
                                <div class="col-12 mt-4 pt-2">
                                    <div class="card explore-feature border-0 rounded text-center bg-white shadow">
                                        <div class="card-body">
                                            <div class="icon rounded-circle shadow-lg d-inline-block">
                                                <smartphone-icon class="fea"></smartphone-icon>
                                            </div>
                                            <h5 class="mt-3 title">Deep roots in the travel market</h5>
                                            <p class="text-muted mb-0">With six years of profound experience in the tourism market, we deliver professional and effective digital marketing solutions for the travel industry.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-12 mt-4 pt-2">
                                    <div class="card explore-feature border-0 rounded text-center bg-white shadow">
                                        <div class="card-body">
                                            <div class="icon rounded-circle shadow-lg d-inline-block">
                                                <message-circle-icon class="fea"></message-circle-icon>
                                            </div>
                                            <h5 class="mt-3 title">Conversion traffic</h5>
                                            <p class="text-muted mb-0">Utilizing the Treep booking engine for reservations.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end Row-->
                        </div>
                        <!--end col-->
                    </div>
                    <!--end Row-->
                </div>
                <!--end col-->

                <div class="col-lg-4 col--4 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                    <div class="section-title ml-lg-4">
                        <p class="text-primary h2 mb-3"><i class="uil uil-apps"></i></p>
                        <h4 class="title mb-3">Why choose us</h4>
                        <p class="text-muted">We not only emphasize promotion but also prioritize conversion. Our one-stop service integrates brand promotion with sales growth, achieving maximum business value.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>

        <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Case study</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                We serve a diverse range of clients spanning across industries such as hotels, beach club, boat operators, scenic spots, and show. Offering specialized digital marketing solutions tailored to the unique market demands of each industry, we are dedicated to collaborating with clients to achieve mutual business growth goals.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-12 mt-4">
            <carousel
              id="customer-testi"
              class="owl-carousel owl-theme"
              dir="ltr"
              :per-page="3"
            >
              <Slide>
                <div
                  class="card customer-testi text-center border-0 shadow rounded m-2"
                >
                  <div class="card-body">
                    <img
                      src="images/client/sealife.jpg"
                      height="50"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " Exceeded the overall exposure target by <span class="text-primary font-weight-bold">158%</span> through collaborations with influencers on both LRB and Douyin. "
                    </p>
                    <h6 class="text-primary">- Sealife</h6>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card customer-testi text-center border-0 shadow rounded m-2"
                >
                  <div class="card-body">
                    <img
                      src="images/client/yona.jpg"
                      height="50"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " Reached potential users through official account operation and advertising, achieving a conversion rate <span class="text-primary font-weight-bold">50%</span> higher than industry peers. "
                    </p>
                    <h6 class="text-primary">- Yona Beach Club</h6>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card customer-testi text-center border-0 shadow rounded m-2"
                >
                  <div class="card-body">
                    <img
                      src="images/client/centara.jpg"
                      height="50"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " Centara, through official account operations , has established a Chinese website, building an authoritative official image in the local market. This effort has increased brand awareness, accumulated brand assets, and enhanced user trust. "
                    </p>
                    <h6 class="text-primary">- Centara</h6>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card customer-testi text-center border-0 shadow rounded m-2"
                >
                  <div class="card-body">
                    <img
                      src="images/client/simon.jpg"
                      height="50"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " Enhanced brand awareness through multi-channel operations, driving UGC content engagement with an interaction volume surpassing the current completion by <span class="text-primary font-weight-bold">170%</span>. "
                    </p>
                    <h6 class="text-primary">- Simon Cabaret Phuket</h6>
                  </div>
                </div>
              </Slide>
            </carousel>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <div class="container mt-100 mt-60">
            <div class="rounded bg-primary p-lg-5 p-4">
                <div class="row align-items-end">
                    <div class="col-md-8">
                        <div class="section-title text-md-left text-center">
                            <h4 class="title mb-3 text-white title-dark">Consult and diagnose for FREE</h4>
                            <p class="text-white-50 mb-0">Find the best service package that fit your marketing goal.</p>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-4 mt-4 mt-sm-0">
                      <div class="text-md-right text-center">
                            <a href="https://treep.cc/demo/?channelType=1" class="btn btn-light">Get Free diagnose</a>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
        </div>
        <!--end container-->
    </section>
    <!--end section-->

    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
